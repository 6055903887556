jQuery(document).ready(function ($) {

  // Slides
  var swiper = new Swiper('.js-home-slider', {
    slidesPerView: 1,
    centeredSlides: true,
    paginationClickable: true,
    watchOverflow: true,
    autoplay: {
      delay: 8000,
    },
    pagination: {
      el: '.js-home-slider .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    //loop: true,
    // breakpoints: {
    //     // when window width is <= 320px
    //     1024: {
    //       slidesPerView: 2,
    //     },
    //     // when window width is <= 480px
    //     768: {
    //       slidesPerView: 1,
    //       spaceBetween: 20
    //     }
    //   }
  });

  var thumbs = new Swiper(".js-slider-thumbs", {
    direction: 'vertical',
    slidesPerView: 3,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    freeMode: true,  
    breakpoints: {
      0: {
        direction: 'horizontal',
        centeredSlides: false,
        slidesPerView: 1,
      },
      375: {
        direction: 'horizontal',
        slidesPerView: 2,
      },
      426: {
        direction: 'horizontal',
        slidesPerView: 3,
      },
      768: {
        direction: 'vertical',
        slidesPerView: 3,
      }
    }
  });

  var swiper = new Swiper(".js-slider", {
    direction: 'vertical',
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: thumbs,
    },
    breakpoints: {
      0: {
        direction: 'horizontal',
        slidesPerView: 1,
      },
      768: {
        direction: 'vertical',
        slidesPerView: 1,
      }
    }
  });

  var thumbs2 = new Swiper(".js-gallery-thumbs", {
    slidesPerView: 4,
    spaceBetween: 15,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    freeMode: true,  
    breakpoints: {
      0: {
        slidesPerView: 2,
      },
      425: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 4,
      }
    }
  });

  var swiper = new Swiper(".js-gallery", {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: thumbs2,
    },
  });

  // Header
  $(window).on('scroll', function() {
    const $html = $('html'),
          $this = $(this);
    
    if ($this.scrollTop() > 130 ) {
      $html.addClass('is-active-header')
    } else {
      $html.removeClass('is-active-header')
    }
  });

  // Remove action
  $('.js-no-action').on('click', function(e) {
    e.preventDefault();
  });

  // Menu mobile
  $('.js-menu-mobile').on('click', function(e) {
    const $html = $('html');

    $html.toggleClass('is-active-menu');
  });

  // Accordion
  $('.js-accordion').on('click', function() {
    $(this).toggleClass('is-active');
    $(this).parent().find('.c-single-products__accordion-content').slideToggle();
  });

  // Animeted on scroll
  $(window).on('scroll', function() {
    const winHeight = $(this).innerHeight(),
          winScroll = $(this).scrollTop() + winHeight;

    $('.js-animated').each(function() {
      const $this = $(this),
            elTop = $this.offset().top;

      if(winScroll >= elTop + winHeight / 3) {
        $this.addClass('is-visible');
      }
    });
  });

  // Animated start
  function start() {
    $('.js-animated-start').addClass('is-visible');
  }

  start();

  // MASKED INPUT
  $(".js-data").mask("99/99/9999");
  $(".js-cpf").mask("999.999.999-99");
  $(".js-cep").mask("99999-999");
  $(".js-cnpj").mask("99.999.999/9999-99");
  $('.js-phone').focusout(function(){
    var phone, element;
    element = $(this);
    element.unmask();
    phone = element.val().replace(/\D/g, '');
    if(phone.length > 10) {
      element.mask("(99) 99999-999?9");
    } else {
      element.mask("(99) 9999-9999?9");
    }
  }).trigger('focusout');

  jQuery('#calcula').submit(function(e){
    e.preventDefault();
  
    var espessura = parseFloat(jQuery('#espessura').val());
    var largura_a = parseFloat(jQuery('#largura_a').val());
    var altura_b = parseFloat(jQuery('#altura_b').val());
    var profundidade_c = parseFloat(jQuery('#profundidade_c').val());
    var profundidade_d = parseFloat(jQuery('#profundidade_d').val());

    var calcula_largura_a = largura_a + (espessura * 2);
    jQuery('#me_largura_a').html(calcula_largura_a);

    var calcula_altura_b = altura_b + (espessura * 2);
    jQuery('#me_altura_b').html(calcula_altura_b);

    var calcula_profundidade_c = profundidade_c + (espessura * 2);
    jQuery('#me_profundidade_c').html(calcula_profundidade_c);

    var calcula_profundidade_d = profundidade_d + (espessura * 2);
    jQuery('#me_profundidade_d').html(calcula_profundidade_d);

    var calcula_volume_de_caixa = (((profundidade_c + profundidade_d) / 2) * altura_b * largura_a) / 1000;
    jQuery('#me_volume_da_caixa').html(calcula_volume_de_caixa);

    return false;
  });

});